import Image from 'next/image'

export default function Card(props){
  const {image, ...rest} = props
  return (
    <article className="aspect-square p-2 bg-gradient-to-b from-lightpurple to-lightblue rounded z-20 shadow-lg" {...rest}>

      <div className="w-full h-full relative">
        <Image src={image} alt='Abella' layout='fill'/>
      </div>

    </article>
  )
}