import clsx from 'clsx'
import Image from 'next/image'
import mint from '../../public/images/mint.svg'
import abella1 from '../../public/images/1.png'
import abella2 from '../../public/images/2.png'
import abella3 from '../../public/images/3.png'
import Card from './Card'


export default function VideoSection(props){
  const {anchor, className, ...rest} = props
  return (
    <section ref={props.anchor} className={clsx(className, 'w-full flex flex-col items-center justify-center')} {...rest}>
        <h2 className="text-6xl mb-6">About</h2>
        <div className='flex justify-center w-full max-w-screen-lg aspect-video mb-12 rounded overflow-hidden'>
        <iframe className='w-full' src="https://www.youtube.com/embed/xNBqcmXe_80" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>

      </div>

      <p className='text-xl w-full max-w-screen-lg'>
        {`Metabella is a collection of 6’969 NFTs, on the Ethereum blockchain, co-created by Abella Danger and Pokmi: two leaders in the adult industry.`}
      </p>
    </section>
  )
}
