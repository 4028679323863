import clsx from 'clsx'
import Image from 'next/image'
import Link from 'next/link'
import abella from '../../public/images/abellaweb.png'


export default function TeamSection(props){
  const {anchor, className, ...rest} = props
  return (
    <section ref={anchor} className={clsx(className, 'w-full flex flex-col items-center justify-center relative overflow-hidden')} {...rest}>
      <h2 className="text-6xl mb-6">Team</h2>
      <div className='max-w-screen-md z-10'>
        <div className='flex flex-col w-full py-8 max-w-screen-md md:-ml-40'>
          <h2 className='hyper text-3xl uppercase'>Abella Danger</h2>
          <p className='text-xl w-full'>
            {`Abella is the most popular adult actress and model. She has more than 12 million followers on her social media accounts. She has made a name for herself after starring in more than a thousand films and winning numerous awards. In 2021, she decided to join Pokmi as an ambassador getting involved in the future of the adult industry through blockchain and NFTs.`}
          </p>
        </div>
        <div className='flex flex-col w-full py-8 max-w-screen-md z-20 md:-ml-40 pb-8'>
          <h2 className='hyper text-3xl uppercase'>Pokmi</h2>
          <p className='text-xl w-full'>
            {`Pokmi is the leading NFT platform dedicated to the adult industry.
Pokmi operates with its own crypto: the Poken ($PKN). The platform, award-winner at the 2022 Xbizz Awards, aims at solving the issues of the adult industry by leveraging cutting-edge technologies; such as age verification, crypto as banking services, privacy protection, ownership certification, etc…`}
          </p>
        </div>
      </div>

      <p className='mt-auto bottom-8 underline z-50'>
        <Link href={'/cgu'}>
        <a>
          Terms &amp; conditions
        </a>
        </Link>

        <Link href={'/legal-notice'}>
        <a className='ml-2'>
          Legal Notices
        </a>
        </Link>
      </p>
      
      <div className='absolute hidden md:block bottom-0 -right-32 w-full md:w-1/2 aspect-square md:z-20'>
        <Image src={abella} alt="Abella" layout={'fill'}/>
      </div>
    </section>
  )
}
