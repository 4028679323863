import clsx from 'clsx'
import Image from 'next/image'
import mint from '../../public/images/mint.svg'
import abella1 from '../../public/images/1.png'
import abella2 from '../../public/images/2.png'
import abella3 from '../../public/images/3.png'
import Card from './Card'


export default function RoadmapSection(props){
  const {anchor, className, ...rest} = props
  return (
    <section ref={anchor} className={clsx(className, 'w-full flex flex-col items-center justify-center overflow-hidden')} {...rest}>
      <h2 className="text-6xl mb-6">Roadmap</h2>
      <div className='w-full py-8 max-w-screen-lg'>
        <h3 className="text-3xl font-bold">{`Metabella Wearables`}</h3>
        <p className='text-xl w-full'>
        To extend the reach of Metabella’s project in the Metaverse we will create a line of wearables 
        (cap, hoodie, pants, sneakers…) designed in close collaboration with Abella Danger.
        </p>
      </div>
      <div className='w-full py-8 max-w-screen-lg'>
        <h3 className="text-3xl font-bold">{`Pokmi Private Club Exclusive access`}</h3>
        <p className='text-xl w-full'>
        Metabella NFT owners will get exclusive access to the Pokmi Private Club, an unique club in Pokmi’s Metaverse Red District. Members of the private club will get access to exclusive events and parties.
<br/><br/>
We already own lands in both Decentraland and Sandbox allowing us to quickly develop this private club.

 </p>
      </div>
      <div className='w-full py-8 max-w-screen-lg'>
        <h3 className="text-3xl font-bold">{`Bonus`}</h3>
        <p className='text-xl w-full'>
        This project will also provide exclusive rewards and benefits only available for Metabella NFT owners. 
<br/>
<br/>
Metabella NFTs will give you access to special content on Pokmi, whitelists and giveaways. Every Metabella NFTs holders will have access to 10 free minutes of Pok&amp;Play each months. They will also act as a membership card for upcoming collections by Pokmi ambassadors (Manuel Ferrara, Kayden Kross, Maitland Ward..).
  </p>
      </div>
    </section>
  )
}
