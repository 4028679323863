import { Swiper, SwiperSlide } from "swiper/react";

import 'swiper/css';

import { EffectCoverflow, Pagination } from "swiper";
import Card from "./Card";
import React from "react";

export default function CardCaroussel(props){
  return (
    <Swiper
      loop={true}
      effect={"coverflow"}
      grabCursor={true}
      centeredSlides={true}
      slidesPerView={'auto'}
      coverflowEffect={{
        rotate: 5,
        stretch: 0,
        depth: 200,
        modifier: 1,
        scale: 1,
        slideShadows: true,
      }}
      pagination={true}
      modules={[EffectCoverflow, Pagination]}
      className="w-full h-5/6"
    >
      {React.Children.map(props.children, (child) => (
        <SwiperSlide style={{maxWidth: '50vh', maxHeight: '50vh', width: 'fit-content' }} className='aspect-square h-full'>
          {React.cloneElement(child, {style: {height: '100%'}})}
          </SwiperSlide>
      ))}
    </Swiper>
  )
}