import { useRef } from 'react'
import type { NextPage } from 'next'
import Head from 'next/head'
import Image from 'next/image'

import { useModalContext } from '../utils/modal'

import { ToastContainer } from '../components/base/Toaster'
import { Modal } from '../components/base/Modal'
import Header from '../components/Header'
import MintSection from '../components/MintSection'
import TeamSection from '../components/TeamSection'
import RoadmapSection from '../components/RoadmapSection'
import VideoSection from '../components/VideoSection'

const Home: NextPage = () => {
  const { content, closeModal, preventClose } = useModalContext()
  
  const mintSection = useRef()
  const aboutSection = useRef()
  const roadmapSection = useRef()
  const teamSection = useRef()

  const scrollTo = (ref) => {
    window.scrollTo({ behavior: 'smooth', top: ref.current.offsetTop })
  }

  return (
    <div className='w-full min-h-screen bg-blue flex flex-col relative overflow-x-hidden text-white' >
      <Head>
        <title>Metabella</title>
        <meta name="description" content="Welcome to Metalbella, Abella Danger's first NFT collection." />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      {/* <img src="/images/bg_smoke.svg" alt="" className='absolute h-full max-w-none fill-red-900 z-10'/>    */}
      <div className='background'></div>
      <Header
        scrollToMint={() => scrollTo(mintSection)}
        scrollToAbout={() => scrollTo(aboutSection)}
        scrollToRoadmap={() => scrollTo(roadmapSection)}
        scrollToTeam={() => scrollTo(teamSection)}
      />
      <main className='z-30 bg-gradient-to-b from-transparent to-purple'>
        <div className=''>
          <MintSection anchor={mintSection} className='w-full h-screen page'/>
          <VideoSection anchor={aboutSection} className='w-full py-10 px-4'/>
          <RoadmapSection anchor={roadmapSection} className='w-full py-10 px-4'/>
          <TeamSection anchor={teamSection} className='w-full h-full md:h-screen px-4'/>
        </div>
      </main>
      <ToastContainer />
      <div className="z-50">
        <Modal open={!!content} onClose={closeModal} preventClose={preventClose}>
          {content}
        </Modal>
      </div>
    </div>
  )
}

export default Home
